import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="slayer"></a><h3>SLAYER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80455.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d8.
    <a id="slayer-requirements"></a><h6>Requirements</h6>
To qualify to become a slayer, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span> +4.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill:</span> <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(dungeoneering) 4 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feat:</span> <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Must have a
power point reserve of at least 1 power point.</p>
    <h6>Class Skills</h6>
The slayer&#8217;s class skills (and the key ability for each skill) are <a href="skillsAll.html#bluff" style={{
      "color": "rgb(87, 158, 182)"
    }}>Bluff</a>
(Cha), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a>
(dungeoneering) (Int), <a href="skillsAll.html#listen" style={{
      "color": "rgb(87, 158, 182)"
    }}>Listen</a>
(Wis), <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft </a>(Int), <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense
Motive</a> (Wis), <a href="skillsAll.html#spot" style={{
      "color": "rgb(87, 158, 182)"
    }}>Spot</a> (Wis), and <a href="skillsAll.html#survival" style={{
      "color": "rgb(87, 158, 182)"
    }}>Survival</a>
(Wis). 
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
4 + Int modifier.</p>
    <a id="table-the-slayer"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Slayer</span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "20%"
          }}>Special</th>
          <th style={{
            "width": "20%"
          }}>Powers Known
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1
          </td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Favored enemy +2, enemy sense 
          </td>
          <td>-
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2
          </td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Brain nausea
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3
          </td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Lucid buffer
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4
          </td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Favored enemy +4
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5
          </td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>-
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6
          </td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Cerebral blind
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7
          </td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Favored enemy +6
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8
          </td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>Breach power resistance
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9
          </td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>Cerebral immunity
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10
          </td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">Blast feedback, favored enemy +8
          </td>
          <td className="last-row">+1 level of existing manifesting class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="slayer-class-features"></a><h6>Class Features</h6>
All the following are class features of the slayer prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Slayers are proficient with all simple and martial weapons and with all
types of armor. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span>
Beginning at 2nd level, a slayer gains additional power points per day
and access to new powers as if she had also gained a level in whatever
manifesting class she belonged to before she added the prestige class.
She does not, however, gain any other benefit a character of that class
would have gained (bonus feats, metapsionic or item creation feats,
psicrystal special abilities, and so on). This essentially means that
she adds the level of slayer to the level of whatever manifesting class
the character has, then determines power points per day, powers known,
and manifester level accordingly.
If a character had more than one manifesting class before she became a
slayer, she must decide to which class she adds the new level of slayer
for the purpose of determining power points per day, powers known, and
manifester level.
If the character did not belong to a manifesting class before taking
this prestige class, she does not gain manifesting levels.</p>
    <a id="slayer-favored-enemy"></a><p><span style={{
        "fontWeight": "bold"
      }}>Favored Enemy (Ex):</span>
When she enters the class, a slayer formally declares a type of psionic
creature as the enemy she detests above all others. Due to her
extensive study of her foes and training in the proper techniques for
combating them, she gains a +2 bonus on <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>,
      <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a>, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>,
and <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival
      </a>checks when using these skills against her favored
enemy.
Likewise, she gets a +2 bonus on weapon damage rolls against creatures
of this kind. At 4th level the bonuses increase to +4, at 7th level to
+6, and at 10th level to +8.</p>
    <a id="slayer-enemy-sense"></a><p><span style={{
        "fontWeight": "bold"
      }}>Enemy Sense (Su):</span> A
slayer can sense the presence of her favored enemy within 60 feet of
herself, even if they are hidden by darkness or walls, but she cannot
discern their exact location.</p>
    <a id="slayer-brain-nausea"></a><p><span style={{
        "fontWeight": "bold"
      }}>Brain Nausea (Su):</span> At
2nd level, a slayer gains a constant defensive ability somewhat similar
to the <a href="psionicPowersAtoC.html#aversion" style={{
        "color": "rgb(87, 158, 182)"
      }}>aversion</a> power. Any creature
that attempts to eat the slayer&#8217;s
brain must succeed on a Will save (DC 15 + slayer&#8217;s class level) or
become disinclined to do so for 24 hours thereafter. A creature that
fails this save may take any action it desires except extracting the
slayer&#8217;s brain (but does not realize it is being so affected). This
ability is active even if the slayer is <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>, <a href="skillsAll.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a>,
or
otherwise <a href="skillsAll.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>.</p>
    <a id="slayer-lucid-buffer"></a><p><span style={{
        "fontWeight": "bold"
      }}>Lucid Buffer (Ex):</span> At
3rd level, a slayer becomes especially skilled at resisting mental
attacks. She gains a +4 competence bonus on saving throws against all
compulsions and mind-affecting effects. This ability is active even if
the slayer is <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>, <a href="skillsAll.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a>,
or
otherwise <a href="skillsAll.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>.</p>
    <a id="slayer-cerebral-blind"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cerebral Blind (Su):</span>
After reaching 6th level, a slayer is protected from all devices,
powers, and spells that reveal location. This ability protects against
information gathering by clairsentience powers or effects that reveal
location.
The ability even foils <a href="psionicPowersAtoC.html#bend-reality" style={{
        "color": "rgb(87, 158, 182)"
      }}>bend reality</a>, <a href="spellsHtoL.html#limited-wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>limited
wish</a>, <a href="spellsMtoO.html#miracle" style={{
        "color": "rgb(87, 158, 182)"
      }}>miracle</a>, <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality
revision</a>, and <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish </a>when they are used to gain
information about the
slayer&#8217;s location (however, <a href="psionicPowersGtoP.html#metafaculty" style={{
        "color": "rgb(87, 158, 182)"
      }}>metafaculty</a> can pierce this
protective
barrier). In the case of <a href="psionicPowersQtoW.html#remote-viewing" style={{
        "color": "rgb(87, 158, 182)"
      }}>remote viewing</a> or <a href="spellsS.html#scrying" style={{
        "color": "rgb(87, 158, 182)"
      }}>scrying</a>
that scans an area a
slayer is in, the effect works, but the slayer simply isn&#8217;t detected.
Remote viewing or scrying attempts that are targeted specifically at a
slayer do not work. This ability is active as long as the slayer is
psionically focused.</p>
    <a id="slayer-breach-power-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Breach Power Resistance (Su):</span>
A slayer of 8th level or higher can enhance her weapon with psionic
might. Each successful melee attack (or ranged attack if the target is
within 30 feet) she makes with her weapon against a creature with power
resistance temporarily reduces its power resistance by 1. Unless the
creature is slain, its lost power resistance returns all at once 12
hours later. This ability is active as long as the slayer is
psionically focused.</p>
    <a id="slayer-cerebral-immunity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cerebral Immunity (Su):</span>
On reaching 9th level, a slayer gains protection from all devices,
powers, and spells that influence the mind. This ability shields her
against almost all mind-affecting powers and effects (though the slayer
can selectively allow powers or spells to affect her). The ability even
foils <a href="psionicPowersAtoC.html#bend-reality" style={{
        "color": "rgb(87, 158, 182)"
      }}>bend reality</a>, <a href="spellsHtoL.html#limited-wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>limited
wish</a>, <a href="spellsMtoO.html#miracle" style={{
        "color": "rgb(87, 158, 182)"
      }}>miracle</a>, <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality
revision</a>, and <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish</a>
when they are used to mentally influence a slayer. This ability is
active as long as the slayer is psionically focused.</p>
    <a id="slayer-blast-feedback"></a><p><span style={{
        "fontWeight": "bold"
      }}>Blast Feedback (Su):</span>
At 10th level, if a slayer makes her saving throw when attacked with
mind blast, the mind blast rebounds upon the attacker. Only the
original attacker is targeted in the rebound effect. If the original
attacker fails a Will saving throw (DC equal to that of the original
attack), the attacker is affected normally by the mind blast.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      